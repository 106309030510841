import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "confirmCreditTips__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    class: "utils__pageContainer confirmCreditTips",
    title: "认证提示"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", null, [
          _createVNode(_component_app_icon, {
            type: "info",
            class: "text-warning"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.tipList[_ctx.validateFor]), 1)
        ]),
        _createVNode(_component_router_link, {
          class: "ant-btn ant-btn-primary",
          to: { name: 'profile.confirm_credit' }
        }, {
          default: _withCtx(() => [
            _createTextVNode("立即认证")
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _: 1
  }))
}