
import { defineComponent } from 'vue';
import { VALIDATE_RESION } from '@/utils/constants';

export default defineComponent({
  name: 'ConfirmCreditTips',

  data(): {
    tipList: any;
    VALIDATE_RESION: any;
    } {
    return {
      tipList: {
        [VALIDATE_RESION.ISSUE_FAPIAO]: '请先完成企业认证后, 再申请开票',
        [VALIDATE_RESION.ADD_STAFF]: '员工人数已达上限, 请认证企业信息, 后添加更多员工',
        [VALIDATE_RESION.TOP_UP_WITH_STATE_NOCERT]: '请先完成企业认证后, 再进行充值',
        [VALIDATE_RESION.TOP_UP_WITH_STATE_WAIT]: '企业认证审核中，请认证成功后再进行充值',
      },
      VALIDATE_RESION,
    };
  },
  computed: {
    validateFor(): number {
      return this.$route.query.for ? +this.$route.query.for : VALIDATE_RESION.ISSUE_FAPIAO;
    },
  },
});
